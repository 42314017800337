import { render, staticRenderFns } from "./ReallocationDetail.vue?vue&type=template&id=138b0dfa"
import script from "./ReallocationDetail.vue?vue&type=script&lang=js"
export * from "./ReallocationDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports