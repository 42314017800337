var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"90%"},scopedSlots:_vm._u([{key:"default",fn:function({ isActive }){return [_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Form Penerimaan Bibit Tambahan")])]),_c('v-card-text',{staticClass:"farmer-assign-wrapper mt-3"},[_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-data-table',{staticClass:"rounded-xl px-1 pt-2",attrs:{"loading":_vm.loading,"headers":_vm.headerAddendumTable,"items":_vm.reallocationList,"search":_vm.search,"items-per-page":_vm.perPage,"footer-props":{
                                itemsPerPageOptions: [5, 10, 25, 50, 100, 200],
                                showCurrentPage: true,
                                showFirstLastPage: true,
                            }},on:{"update:items-per-page":($p) => (_vm.perPage = $p)},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"list-header py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5 d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('div'),_c('geko-input',{attrs:{"item":{
                                            label: 'Cari Bibit / Lahan',
                                            type: 'text'
                                        }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.index",fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.received",fn:function({ item }){return [_c('geko-input',{attrs:{"item":{
                                        type: 'number',
                                    }},model:{value:(_vm.formData[item.farmer_no + '-' + item.lahan_no + '-' + item.rel_tree_id].received),callback:function ($$v) {_vm.$set(_vm.formData[item.farmer_no + '-' + item.lahan_no + '-' + item.rel_tree_id], "received", $$v)},expression:"formData[item.farmer_no + '-' + item.lahan_no + '-' + item.rel_tree_id].received"}})]}},{key:"item.damaged",fn:function({ item }){return [_c('geko-input',{attrs:{"item":{
                                        type: 'number',
                                    }},model:{value:(_vm.formData[item.farmer_no + '-' + item.lahan_no + '-' + item.rel_tree_id].damaged),callback:function ($$v) {_vm.$set(_vm.formData[item.farmer_no + '-' + item.lahan_no + '-' + item.rel_tree_id], "damaged", $$v)},expression:"formData[item.farmer_no + '-' + item.lahan_no + '-' + item.rel_tree_id].damaged"}})]}},{key:"item.missing",fn:function({ item }){return [_c('geko-input',{attrs:{"item":{
                                        type: 'number',
                                    }},model:{value:(_vm.formData[item.farmer_no + '-' + item.lahan_no + '-' + item.rel_tree_id].missing),callback:function ($$v) {_vm.$set(_vm.formData[item.farmer_no + '-' + item.lahan_no + '-' + item.rel_tree_id], "missing", $$v)},expression:"formData[item.farmer_no + '-' + item.lahan_no + '-' + item.rel_tree_id].missing"}})]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-btn',{staticClass:"float-right",attrs:{"variant":"success","type":"submit"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-seed")]),_c('span',{staticClass:"ml-1"},[_vm._v("Konfirmasi Diterima")])],1)],1)],1)],1)]}}],null,true)})],1)],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }