export default [
    {
        key: "index",
        sortable: false,
        text: "No",
        value: "index",
      },
      {
        key: "distribution_date",
        sortable: false,
        text: "Tanggal Distribusi",
        value: "distribution_date",
      },
      {
        key: "rel_loading_line_id",
        sortable: false,
        text: "Loading Line",
        value: "rel_loading_line_id",
      },
      {
        key: "rel_vehicle_id",
        sortable: false,
        text: "Kendaraan",
        value: "rel_vehicle_id",
      },
      {
        key: "rel_driver_id",
        sortable: false,
        text: "Pengemudi",
        value: "rel_driver_id",
      },
      {
        key: "total_load",
        sortable: false,
        text: "Total Terdistribusi",
        value: "total_load",
      },
      {
        key: "qty_unloading",
        sortable: false,
        text: "Total Unload",
        value: "qty_unloading",
      },
      {
        key: "scanned_time",
        sortable: false,
        text: "Waktu Scan",
        value: "scanned_time",
      }
]