var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-breadcrumbs',{staticClass:"breadcrumbsmain",attrs:{"dark":_vm.$store.state.theme == 'dark',"items":[
      {
        text: 'Menu',
        disabled: true,
        href: 'breadcrumbs_dashboard',
      },
      {
        text: `${_vm.config.title}`,
        disabled: true,
        href: 'breadcrumbs_link_1',
      },
    ],"divider":">","large":"","data-aos":"fade-right"}}),_c('v-container',{attrs:{"fluid":""}},[_c('CalendarDistributionDialog',{attrs:{"show":_vm.selectedOpen,"selectedData":_vm.selectedEvent},on:{"hide":() => (_vm.selectedOpen = false)}}),_c('v-card',{staticClass:"pa-5",attrs:{"rounded":"xl","data-aos-delay":"200"}},[_c('v-overlay',{staticClass:"rounded-xl",attrs:{"value":_vm.isLoading,"absolute":"","color":"white"}},[_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('v-progress-circular',{staticClass:"mt-10",attrs:{"indeterminate":"","color":"green","size":"72","width":"7"}})],1)]),_c('v-toolbar',{staticClass:"mb-3",attrs:{"rounded":"xl","flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Today ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('geko-input',{attrs:{"item":{
            label: 'Tanggal Distribusi',
            hide_label: true,
            type: 'date',
            validation: [],
          }},model:{value:(_vm.config.calendarOptions.focus),callback:function ($$v) {_vm.$set(_vm.config.calendarOptions, "focus", $$v)},expression:"config.calendarOptions.focus"}})],1),_c('v-sheet',{attrs:{"height":"600","rounded":"xl"}},[_c('v-calendar',{ref:"calendar",attrs:{"type":_vm.config.calendarOptions.type,"events":_vm.events,"event-overlap-threshold":30,"event-color":_vm.getEventColor,"color":"success"},on:{"click:more":_vm.viewDay,"click:date":_vm.viewDay,"click:event":_vm.showEvent,"change":_vm.getEvents},model:{value:(_vm.config.calendarOptions.focus),callback:function ($$v) {_vm.$set(_vm.config.calendarOptions, "focus", $$v)},expression:"config.calendarOptions.focus"}})],1),_c('v-toolbar',{staticClass:"pt-2",attrs:{"rounded":"xl","flat":""}},[_c('v-spacer'),_c('geko-input',{attrs:{"item":{
            type: 'select-radio',
            hide_label: true,
            validation: [],
            label: 'Tampilan',
            option: {
              clearable: false,
              default_options: _vm.config.calendarOptions.types,
              list_pointer: {
                code: 'code',
                label: 'label',
                display: 'label',
              },
            },
          }},model:{value:(_vm.config.calendarOptions.type),callback:function ($$v) {_vm.$set(_vm.config.calendarOptions, "type", $$v)},expression:"config.calendarOptions.type"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }